<template>
  <v-main wrap class="background">
    <v-container class="justify-center" fill-height>
      <change-password :user="user" :userName="UsrName" @close="close()" v-if="showChangePassword" />
      <v-row class="justify-center">
        <v-col xs="12" sm="8" md="6" lg="4">
          <v-card light class="px-12 py-10" style="box-shadow: 0 0px 0px 0 !important ">
            <v-card color="primary" elevation="0" dark class="py-5 mb-n5" style="top:-65px">
              <div class="text-center" style="font-size:32px; font-weight:350"><b>Sun</b>Expert</div>
            </v-card>
            <v-card-subtitle class="text-center">
              <strong> Ingrese sus Credenciales</strong>
            </v-card-subtitle>
            <v-card-text>
              <s-text v-model="UsrName" ref="UsrName" label="Nombre de Usuario" class="px-2">
                <template v-slot:prepend-inner>
                  <v-icon class="py-1">mdi mdi-account</v-icon>
                </template>
              </s-text>
              <s-text
                v-model="UsrPassword"
                :type="visiblePassword ? 'text' : 'password'"
                ref="UsrPassword"
                label="Contraseña"
                class="px-2 mt-2"
                @keyupEnter="validateUser()"
                @click:append="visiblePassword = !visiblePassword"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="py-1">mdi mdi-lock</v-icon>
                </template>
                <template v-slot:append>
                  <v-icon class="py-1" @click="visiblePassword = !visiblePassword">{{ visiblePassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                </template>
              </s-text>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn block color="primary" :loading="validatingUser" :disabled="validatingUser" @click="validateUser()">
                Iniciar Sesión
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import _sAuth from "@/services/AuthService";
import ChangePassword from "@/views/Auth/ChangePassword";
export default {
  components: { ChangePassword },
  data: () => ({
    user: null,
    showChangePassword: false,
    UsrName: "",
    UsrPassword: "",
    visiblePassword: false,
    validatingUser: false,
  }),
  methods: {
    close() {
      this.showChangePassword = false;
      this.validatingUser = false;
    },
    validateUser() {
      this.validatingUser = true;

      if (this.UsrName.length > 0 && this.UsrPassword.length > 0) {
        _sAuth
          .login(this.UsrName, this.UsrPassword)
          .then((response) => {
            if (response.status == 200) {
              this.user = response.data.user;

              localStorage.clear();
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("UsrID", response.data.user.UsrID);
              localStorage.setItem("UsrName", response.data.user.UsrName);
              localStorage.setItem("isIdle", false);
              localStorage.setItem("TimeToken", response.data.tokenRefresh * 60000 - 60000);
              localStorage.setItem("TimeTokenCount", response.data.tokenRefresh * 60000 - 60000);

              if (response.data.user.UsrPasswordChange == 0) {
                this.$router.replace("/");
              } else this.showChangePassword = true;
            }
          })
          .catch((error) => {
            this.validatingUser = false;
          });
      } else this.validatingUser = false;
    },
  },
  mounted(){
    this.$refs.UsrName.focus();
    // this.UsrName = process.env.BASE_URL_VUE;
  },
};
</script>
<style scoped>
html {
  overflow: hidden !important;
}
.background {
  height: 100%;
  background-repeat: no-repeat;
  /* Indicamos que no se repetira */
  background-size: cover !important;
  /* Encajamos la imagen al 100% del ancho */
  background-attachment: fixed !important;
  /* Establecemos una posicion fija para la imagen */
  /* background-image: linear-gradient( to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url("./../assets/SunshineWallpaper.jpg"); */
  background-image: url("../../assets/SunExpertWallpaper.jpg");
}
</style>
