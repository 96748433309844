<template>
  <v-row justify="center">
    <v-dialog persistent v-model="dialog" max-width="320">
      <v-card>
        <v-card-title class="headline">{{ usrName }}</v-card-title>
        <v-card-subtitle class="headline" style="font-size:12px !important;"
          >Requiere cambio de contraseña</v-card-subtitle
        >
        <v-divider></v-divider>
        <v-card-text style="padding-bottom:0px;">
          <v-col>
            <s-text
              label="Nueva Contraseña"
              type="password"
              v-model="password"
              autofocus
            ></s-text>
          </v-col>
          <v-col>
            <s-text
              label="Confirmar Nueva Contraseña"
              type="password"
              v-model="passwordConfirm"
            ></s-text>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">Cancelar </v-btn>
          <v-btn color="primary" @click="save()">Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import AuthService from "../../services/AuthService";
export default {
  name: "ModalTimeSession",
  props: { userName: "" ,user: null},
  data: () => ({
    dialog: true,
    password: "",
    passwordConfirm: "",
  }),
  components: {},
  methods: {
    save() {
      if (this.password.trim() == this.passwordConfirm.trim()){
  
        AuthService.changePassword(this.password.trim(),this.user)
          .then(() => {
            this.$fun.alert("Contraseña Actualizada", "success");

            this.dialog = false;

            this.$router.replace("/");
          })
          .catch((error) => {
            console.log(error);
            localStorage.clear();
            this.$emit("close");
          });}
      else
        this.$fun.alert(
          "La Confirmación de la contraseña es distinta",
          "warning"
        );
    },
    cancel() {
      localStorage.clear();
      this.dialog = false;
      this.$emit("close");
    },
  },
  computed: {
    usrName() {
      return this.userName.toUpperCase();
    },
  },
};
</script>
<style scoped></style>
